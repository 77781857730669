.post-grid {
  height: 100%;
  position: relative;
  transition: box-shadow ease-in-out 0.3s;
  border-radius: $radius-var;
  box-shadow: 0 0 0rem 1px rgba(#000, 0.05);
  overflow: hidden;

  // cover
  &-cover {
    display: block;
    margin: 0 0 1.5rem;
    position: relative;
    border-bottom: 1px solid rgba(#000, 0.05);
  }
  &-img {
    min-width: 100%;
    height: 11rem;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: $radius-var;
    border-top-right-radius: $radius-var;
  }

  &-cats {
    padding: 0 1rem;
    line-height: 1.4;
    display: flex;
    flex-wrap: wrap;

    &-link {
      text-decoration: none;
      font-size: 0.75rem;
      margin-right: 0.5rem;
      color: #999;
      &::before {
        content: "#";
        display: inline-block;
        margin-right: 0.1rem;
      }

      &:hover {
        color: $color;
      }
    }
  }

  // title
  &-title {
    font-size: 1.125rem;
    font-weight: 600;
    padding: 1rem 1rem 0;
  }

  &-link {
    color: #000;
    transition: color ease-in-out 0.2s;
    text-decoration: none;
    &:hover {
      color: $color;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 11rem;
      background-color: transparent;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  // desc
  &-desc {
    font-size: 0.875rem;
    color: #777;
    margin: 0;
    line-height: 1.75;
    transition: color ease-in-out 0.2s;
    padding: .5rem 1rem 1rem;
  }

  // meta
  &-meta {
    font-size: 0.75rem;
    color: #555;
    border-top: 1px solid rgba(#000, 0.05);
    // margin-bottom: 1.5rem;
    opacity: 0.7;
    padding: 1rem;
    margin-top: auto;
    position: relative;
    a {
      text-decoration: none;
      color: #555;
      &:hover,
      &:focus {
        color: $color;
      }
    }
  }
  &-author {
    display: flex;
    align-items: center;
    margin-right: 0.875rem;
    &-img {
      border-radius: 50rem;
      margin-right: 0.25rem;
      filter: grayscale(100%);
    }
    &-name {
      text-transform: capitalize;
    }
  }
  &-date {
    display: flex;
    align-items: center;
    margin-right: 0.875rem;
    &-icon {
      margin-right: 0.375rem;
    }
  }

  &-more {
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 2.5rem;
    height: 100%;
    line-height: 3.125rem;
    font-weight: 500;

    .bi {
      margin-left: 0.1rem;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // hover item
  &:hover {
    box-shadow: 0 0 10rem rgba(#000, 0.1);
    border-color: #fff;
    .post-grid-link {
      color: $color;
    }
    .post-grid-desc {
      color: #000;
    }
    .post-grid-more {
    }
  }
}
