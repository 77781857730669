.featured {
  margin-bottom: 5rem;

  // cover
  &-cover {
    margin: 0;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: $radius-var;
    box-shadow: inset 0 0 0 1px rgba(#000, 0.05);

    @media screen and (max-width: 991px) {
      height: 20rem;
    }
    &-link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-img {
      border-radius: $radius-var;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  // content
  &-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  // title
  &-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 1.3;
  }
  &-link {
    text-decoration: none;
    color: #000;
    &:hover {
      color: $color;
    }
  }

  // meta
  &-meta {
    font-size: 0.875rem;
    color: #777;
    margin-bottom: 1.5rem;
    opacity: 0.7;
    a {
      color: #777;
      &:hover {
        color: #000;
      }
    }
  }
  &-author {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    &-img {
      border-radius: 50rem;
      margin-right: 0.25rem;
      filter: grayscale(100%);
    }
    &-name {
      text-transform: capitalize;
    }
  }
  &-date {
    display: flex;
    align-items: center;
    margin-right: 0.875rem;

    &-icon {
      margin-right: 0.375rem;
    }
    &-time {
    }
  }
  &-cat {
    display: flex;
    align-items: center;
    &-icon {
      margin-right: 0rem;
    }
    &-title {
      text-decoration: none;
    }
  }

  // desc
  &-desc {
    color: #444;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
  &-more {
    font-weight: 500;
    font-size: 0.875rem;
    color: $color;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }

  // carousel nav
  &-prev,
  &-next {
    width: 3rem;
    opacity: 0;
  }
  &-prev {
    left: -4rem;
  }
  &-next {
    right: -3.5rem;
  }

  // hover
  &:hover {
    .featured-next,
    .featured-prev {
      opacity: 0.2;
    }
  }

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid rgba(#000, 0.03);
    margin: -2rem -1.5rem 2rem;
    background-color: var(--bs-light);

    // content
    &-content {
      padding: 0;
      padding: 1rem 1rem 0;
    }
    // title
    &-title {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
    // desc
    &-desc {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }
  }
}
