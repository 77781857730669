.post {
  &-cover {
    width: 100%;
    height: 20rem; // TODO: maybe make it possible to customize it from admin panel?
    margin: 0; // reset the figure margin
    position: relative;
    background-color: #000;
    &-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.9; // For when we have a very white cover.
    }
    &-caption {
      display: block;
      max-width: 100%;
      width: calc(var(--bf-post-width) + 8rem);
      position: relative;
      bottom: calc(8rem / 2);
      margin: -1.5rem auto 0;
      color: #fff;
      font-size: 0.625rem;
      text-align: right;
      line-height: 1rem;
      a,
      span {
        border-radius: $radius;
        display: inline-block;
        padding: 0 0.25rem;
        opacity: 0.7;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        &:hover {
          opacity: 1;
          background-color: #000;
        }
      }
    }

    // This ::after make a white header background on the top of the post title.
    &::after {
      content: "";
      display: block;
      height: calc(8rem / 2);
      width: calc(var(--bf-post-width) + 8rem);
      max-width: 100%;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: #fff;
      border-top-left-radius: $radius-var;
      border-top-right-radius: $radius-var;
    }
  }

  &-container {
    max-width: calc(
      var(--bf-post-width) + 3rem
    ); // calc and maintain the actual with
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  // header
  &-title {
    margin-bottom: 2.5rem;
    line-height: 1.1;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 2.75rem;
    color: #000;
  }

  &-meta {
    margin-bottom: 2.5rem;
    line-height: 1.4;
    font-size: 0.875rem;
    font-weight: 300;
    color: #666;
    white-space: nowrap;
    cursor: default;

    &-item {
      &:not(:last-of-type) {
        margin-right: 1.75rem;
      }
    }

    &-label {
      display: block;
      font-size: 0.75rem;
      color: #999;
    }

    a {
      color: #666;
      text-decoration: none;
      &:hover {
        color: $color;
      }
    }

    &-btn {
      height: 2rem;
      width: 2rem;
      border: 0;
      margin: 0;
      padding: 0;
      background: none;
      text-align: center;

      .bi {
        width: 1.25rem;
        height: 100%;
        fill: #888;
      }

      &:hover {
        .bi {
          fill: $color;
        }
      }
    }

    &-author {
      display: flex;
      align-items: center;
      &-img {
        margin-right: 0.5rem;
        border-radius: 100%;
      }
      &-name {
        text-transform: capitalize;
      }
    }

    &-cats {
      word-break: keep-all;
      &-list {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      &-item {
        &:not(:last-child) {
          margin-right: 0.125rem;
          .post-meta-cats-link {
            &::after {
              content: ",";
            }
          }
        }
      }
    }
  }

  // content
  &-content {
    line-height: 1.8;
    margin-bottom: 4rem;
    hyphens: auto;
    a {
      overflow-wrap: break-word;
      word-break: break-all;
    }
    iframe,
    video,
    img {
      border-radius: 0.125rem;
      width: 100%;
    }

    audio,
    video {
      margin-bottom: 1rem;
      width: 100%;
      max-width: 100%;
    }
  }

  // footer
  &-footer {
    margin-bottom: 4rem;
  }

  // author
  &-author {
    margin-bottom: 4rem;
    padding: 2rem;
    border-radius: var(--bf-radius);
    background-color: var(--bs-light);
    &-name {
      margin: 0 0 0.25rem;
      font-size: 1.125rem;
      text-transform: capitalize;
    }
    &-bio {
      margin-bottom: 0;
      font-size: 0.875rem;
      font-weight: 400;
      color: #000;
    }
    &-cover {
      margin: 0 1.5rem 0 0;
      min-width: 5rem;
    }
    &-img {
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: -2rem;

    &-cover {
      margin-bottom: 2rem;
      height: 14rem;
      &-caption {
        display: none;
      }
      &::after {
        border-radius: 0;
        display: none;
      }
    }

    &-title {
      font-size: 1.5rem;
      letter-spacing: 0;
    }

    &-meta {
      font-size: 0.75rem;
      &-item {
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
      &-cats {
        &-link {
          &::after {
            display: none;
          }
        }
        &-item {
          display: none;

          &:nth-child(1) {
            display: block;
          }
        }
      }
    }
  }
}