.header {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &-title {
    display: inline-block;
    margin: 0;
    font-size: var(--bf-header-title-size);
    font-weight: var(--bf-header-title-weight);
    color: var(--bf-header-title-color);
    line-height: 1.2;
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--bf-header-title-hover);
    }
  }

  &-desc {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--bf-header-desc-color, #666);
  }

  &-logo {
    display: block;
    &-img {
      width: var(--bf-header-logo-width);
      height: var(--bf-header-logo-height);
    }
  }

  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 2rem;
    background-color: var(--bs-light);
  }
}
